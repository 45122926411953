import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'

import { LanguageSwitcher } from './LanguageSwitcher'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export const TopMenu = ({ topMenu, activeDocMeta }) => {
  const renderedMenuLinks = topMenu
    ? topMenu.menu_links.map((menuLink, index) => {
      //console.log('menuLink: ', menuLink.link.url)
      let url = menuLink.link.url;

      return (
        <div key={`top-nav-${index}`}>
          <Nav.Link href={url}>{menuLink.label.text}</Nav.Link>
        </div>
        
      )})
    : null

  const showBanner = false
  
  return (
    <>
      {showBanner &&
      <div className="bg-primary" style={{color:"#fff"}}>
        <div className="container d-flex justify-content-center align-items-center p-3">
          <div className="row gx-3 gy-2">
            <div className="col-auto d-flex align-items-center">
              <p className="mb-0 text-center align-middle h5" style={{fontWeight:"300", fontSize:"17px"}}>🙏 Stimmt für City-Eats & Sifi-Eats im DEHOGA Startup Cup ab! 🏆</p>
            </div>
            <div className="col-auto mx-auto">
              <a href="https://go.sifi-eats.de/dehoga-cup-onsite" target="_blank" className="btn btn-light">Jetzt abstimmen!</a>
            </div>
          </div>
        </div>
      </div>}
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="border-bottom border-2">
        <div className="container">
        <Navbar.Brand href="/"><img className="" height="40" width="auto" src={topMenu.logo.url} alt={"Sifi-Eats Logo"}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {renderedMenuLinks}
          </Nav>
        </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      logo {
        url
      }
      menu_links {
        label {
          richText
          html
          text
        }
        link {
          id
          url
        }
      }
    }
  }
`
